export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/accounting": [3],
		"/addons": [4],
		"/bills": [5],
		"/customers": [6],
		"/dashboard": [7],
		"/foodcosting": [8],
		"/foodcosting/categories": [9],
		"/foodcosting/cost-analysis/dashboard": [10],
		"/foodcosting/cost-analysis/recipe/[id]": [11],
		"/foodcosting/ingredientcost": [12],
		"/foodcosting/ingredients": [13],
		"/foodcosting/recipes": [14],
		"/login": [15],
		"/pos": [16],
		"/pos/[tableid]": [17],
		"/pos/[tableid]/[ticketid]/ticket": [18],
		"/register": [19],
		"/reports": [20],
		"/setup": [21],
		"/tickets": [22]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';